import Recorder from '@/plugins/recorder'
let recorder = new Recorder()

let myRecorder = {
  state: 'stop',
  playState: 'playend',
  fileSize: 0,
  permission: false,
  start: function(that) {
    if (!this.permission) {
      Recorder.getPermission().then(() => {
        this.permission = true
      }, () => {
        that.$message.error('Please open the microphone permission.')
      })
    }
    recorder.start()
    this.state = 'start'
  },
  stop: function() {
    recorder.stop()
    this.state = 'stop'
    this.fileSize = recorder.fileSize
  },
  play: function() {
    recorder.play()
  },
  stopPlay: function() {
    recorder.stopPlay()
  },
  getRecordAnalyseData: function() {
    return recorder.getRecordAnalyseData()
  },
  getRecordBlob: function() {
    return recorder.getWAVBlob()
  }
}

recorder.onplay = () => {
  myRecorder.playState = 'play'
}

recorder.onplayend = () => {
  myRecorder.playState = 'playend'
}

export default myRecorder